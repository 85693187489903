import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class TrackingBehaviorProvider extends HttpRequest {
  getTrackingBehaviorReportByOverall (query) {
    this.setHeader(getAuthToken())
    return this.get('tracking-behavior/report-overall', query)
  }

  getTrackingBehaviorReportByWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('tracking-behavior/report-warehouse', query)
  }
}

export default TrackingBehaviorProvider
